.report {
  display: flex;
}

.report_info-block {
  display: flex;
  margin-bottom: 8px
}

.report_info-title {
  display: inline;
  margin: 0;
  min-width: 120px;
  color: rgb(139, 144, 151)
}

.report_info-text {
  margin: 0;
  display: inline;
}

.report_name {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}

.report_table {
  width: 100%;
}

td {
  padding: 10px;
}

thead {
  background: rgba(234, 236, 238, 1);
  height: 40px;
}

th {
  text-align: left;
  padding: 10px;
}

tr {
  height: 40px;
}

tr:nth-child(even) {
  background: #EAECEE;
}

@media (max-width:520px) {
  td {
    font-size: 14px;
  }

  .report_name {
    font-size: 18px;
  }

}

.viewPdfDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5%;
  padding-bottom: 2%;
}

.pdfete {
  /* object-fit: fill; */
  object-position: 100% 100%;
}